<template>
  <div class="hero">
    <div class="carousel tw-flex tw-justify-center tw-pt-[72px] md:tw-pt-[82px]">
      <!-- Adjusted the height of the hero section to be the total vh minus the height of the fixed nav bar -->
      <div
        class="carousel-inner tw-relative tw-w-full tw-h-[calc(100vh-82px)] tw-overflow-hidden tw-bg-white"
      >
        <!-- Control the transition and the content of the slides -->
        <CarouselItem
          v-for="(slide, index) in slidesInfo"
          :key="index"
          :slide="slide"
          :current-slide="currentSlide"
          :index="index"
        />
        <!-- Control the arrow buttons on the side -->
        <CarouselControls :next-slide="nextSlide" :prev-slide="prevSlide" />
        <!-- Control the pagination of the slides -->
        <CarouselPagination
          :current-slide="currentSlide"
          :slide-count="slideCount"
          :change-slide="changeSlide"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import CarouselItem from './carousel/CarouselItem.vue';
import CarouselControls from './carousel/CarouselControls.vue';
import CarouselPagination from './carousel/CarouselPagination.vue';
import fetchImageUrl from '@/lib/fetchImageUrl';
import getSinglePageInfo from '@/lib/getSinglePageInfo';
import targetData from '@/lib/targetData';
// The index of the current slide. Start from 0.
const currentSlide = ref<number>(0);
const slidesInfo = ref<any[]>([]);

// The number of the slides
const slideCount = ref<number>(0);

// The ID of the current slide interval (Store it here, so we can clear the interval when needed)
const slideInterval = ref<null | number>(null);

async function processSlides() {
  const pageInfo = await getSinglePageInfo('homepage');
  console.log(pageInfo);
  // const targetCtaTextData: any = targetData(pageInfo, 'cta');
  // const targetCtaLinkData: any = targetData(pageInfo, 'cta_link');

  const targetHeroSlideData: any = targetData(pageInfo, 'hero_group');
  const processedData = await Promise.all(
    targetHeroSlideData?.hero_group?.map(async (slide: any) => {
      const headline = slide.parts.find((part: any) => part.name === 'hero_headline');
      const description = slide.parts.find((part: any) => part.name === 'hero_description');
      const image = slide.parts.find((part: any) => part.name === 'hero_image');
      const cta_hero = slide.parts.find((part: any) => part.name === 'cta_hero');
      const cta_hero_link = slide.parts.find((part: any) => part.name === 'cta_hero');
      return {
        headline: headline?.content || '',
        description: description?.content.replace(/\n/g, ' ') || '',
        image_id: image?.image_id || '',
        image: await fetchImageUrl(image?.image_id || ''),
        cta_hero: cta_hero?.content || '',
        cta_hero_link: cta_hero_link?.content || '',
      };
    }) || [],
  );
  console.log(slidesInfo.value);
  slidesInfo.value = processedData;
  slideCount.value = processedData.length;
}

// Set 3-second interval to enable automatic slide changing
const incrementSlideIndex = () => {
  slideInterval.value = setInterval(() => {
    currentSlide.value =
      currentSlide.value < slidesInfo.value.length - 1 ? currentSlide.value + 1 : 0;
  }, 10000);
};

// Clear the current Slide inteval and reset the 3-second interval for changing slides
const resetInteval = () => {
  if (slideInterval.value !== null) {
    clearInterval(slideInterval.value);
    incrementSlideIndex();
  }
};

// Change the current slide to the slide with +1 index. Go back to the first one if it is already the last index
const nextSlide = () => {
  currentSlide.value =
    currentSlide.value < slidesInfo.value.length - 1 ? currentSlide.value + 1 : 0;
  resetInteval();
};

// Change the current slide to the slide with -1 index. Go to the last index if it is already the first index
const prevSlide = () => {
  currentSlide.value =
    currentSlide.value > 0 ? currentSlide.value - 1 : slidesInfo.value.length - 1;
  resetInteval();
};

// Change the slide to the slide with specific index
const changeSlide = (index: number) => {
  currentSlide.value = index;
  resetInteval();
};

// Initiate interval for auto-change of slides when the component is mounted
onMounted(() => {
  incrementSlideIndex();
  slideCount.value = slidesInfo.value.length;
  processSlides();
});

// Cancel the interval for changing slides when the component is unmounted
onUnmounted(() => {
  if (slideInterval.value !== null) {
    clearInterval(slideInterval.value);
  }
});
</script>
<style lang=""></style>

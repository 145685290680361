<template lang="">
  <div>
    <a href="/contact-us" class="tw-text-white tw-no-underline">
      <button
        class="tw-text-sm md:tw-text-base tw-bg-primary tw-rounded-full tw-px-6 tw-py-4 tw-cursor-pointer tw-z-40 tw-font-bold hover:tw-bg-primary/70"
      >
        Request a demo
      </button>
    </a>
  </div>
</template>
<script setup lang="ts"></script>
<style lang=""></style>
